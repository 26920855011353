.center {
  display: block;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
}

.full-screen-center {
  display: flex;
  vertical-align: middle;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.grecaptcha-badge {
  margin: 0 auto !important;
}

.vertical-center {
  display: flex !important;
  vertical-align: middle !important;
  align-items: center !important;
  align-self: center !important;
  justify-content: center !important;
  flex-flow: column !important;
}

.vertical-middle {
  vertical-align: middle;
}

.table-center {
  display: table-cell;
  vertical-align: middle;
}