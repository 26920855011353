
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import '~@angular/material/theming';
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat-core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $app-primary: mat-palette($mat-indigo);
// $app-accent: mat-palette($mat-pink, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $app-warn: mat-palette($mat-red);

// // Create the theme object (a Sass map containing all of the palettes).
// $app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #FFD256;
  --ion-color-primary-rgb: 255,210,86;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #e0b94c;
  --ion-color-primary-tint: #ffd767;

  /** secondary **/
  --ion-color-secondary: #FFFAE8;
  --ion-color-secondary-rgb: 255,252,242;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #e0ded5;
  --ion-color-secondary-tint: #fffcf3;

  /** tertiary **/
  --ion-color-tertiary: #FFEEB5;
  --ion-color-tertiary-rgb: 255,238,181;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #e0d19f;
  --ion-color-tertiary-tint: #fff0bc;

  /** success **/
  --ion-color-success: #A6C64C;
  --ion-color-success-rgb: 166,198,76;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #92ae43;
  --ion-color-success-tint: #afcc5e;

  /** warning **/
  --ion-color-warning: #FEDD6B;
  --ion-color-warning-rgb: 254,221,107;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0c25e;
  --ion-color-warning-tint: #fee07a;

  /** danger **/
  --ion-color-danger: #BC5F5F;
  --ion-color-danger-rgb: 188,95,95;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #a55454;
  --ion-color-danger-tint: #c36f6f;

  /** dark **/
  --ion-color-dark: #464646;
	--ion-color-dark-rgb: 70,70,70;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #3e3e3e;
	--ion-color-dark-tint: #595959;

  /** medium **/
  --ion-color-medium: #939393;
  --ion-color-medium-rgb: 147,147,147;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #818181;
  --ion-color-medium-tint: #9e9e9e;

  /** light **/
  --ion-color-light: #F7F7F7 !important;
  --ion-color-light-rgb: 247,247,247 !important;
  --ion-color-light-contrast: #000000 !important;
  --ion-color-light-contrast-rgb: 0,0,0 !important;
  --ion-color-light-shade: #d8d8d8 !important;
  --ion-color-light-tint: #f6f6f6 !important;

  /** grey **/
  --ion-color-grey: #EFEFEF;
  --ion-color-grey-rgb: 237,237,237;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0,0,0;
  --ion-color-grey-shade: #d1d1d1;
  --ion-color-grey-tint: #EFEFEF;
  
  /** white **/
  --ion-color-white: #FFFFFF;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #44380B;
  --ion-color-white-contrast-rgb: 68, 56, 11;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /** black **/
  --ion-color-black: #151515;
  --ion-color-black-rgb: 21,21,21;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255,255,255;
  --ion-color-black-shade: #121212;
  --ion-color-black-tint: #2c2c2c;

  .ion-color-primary {
    --ion-color-base: var(--ion-color-primary) !important;
    --ion-color-base-rgb: var(--ion-color-primary-rgb) !important;
    --ion-color-contrast: var(--ion-color-primary-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-primary-shade) !important;
    --ion-color-tint: var(--ion-color-primary-tint) !important;
  }
  
  .ion-color-secondary {
    --ion-color-base: var(--ion-color-secondary) !important;
    --ion-color-base-rgb: var(--ion-color-secondary-rgb) !important;
    --ion-color-contrast: var(--ion-color-secondary-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-secondary-shade) !important;
    --ion-color-tint: var(--ion-color-secondary-tint) !important;
  }
  
  .ion-color-tertiary {
    --ion-color-base: var(--ion-color-tertiary) !important;
    --ion-color-base-rgb: var(--ion-color-tertiary-rgb) !important;
    --ion-color-contrast: var(--ion-color-tertiary-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-tertiary-shade) !important;
    --ion-color-tint: var(--ion-color-tertiary-tint) !important;
  }
  
  .ion-color-success {
    --ion-color-base: var(--ion-color-success) !important;
    --ion-color-base-rgb: var(--ion-color-success-rgb) !important;
    --ion-color-contrast: var(--ion-color-success-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-success-shade) !important;
    --ion-color-tint: var(--ion-color-success-tint) !important;
  }
  
  .ion-color-warning {
    --ion-color-base: var(--ion-color-warning) !important;
    --ion-color-base-rgb: var(--ion-color-warning-rgb) !important;
    --ion-color-contrast: var(--ion-color-warning-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-warning-shade) !important;
    --ion-color-tint: var(--ion-color-warning-tint) !important;
  }
  
  .ion-color-danger {
    --ion-color-base: var(--ion-color-danger) !important;
    --ion-color-base-rgb: var(--ion-color-danger-rgb) !important;
    --ion-color-contrast: var(--ion-color-danger-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-danger-shade) !important;
    --ion-color-tint: var(--ion-color-danger-tint) !important;
  }
  
  .ion-color-light {
    --ion-color-base: var(--ion-color-light) !important;
    --ion-color-base-rgb: var(--ion-color-light-rgb) !important;
    --ion-color-contrast: var(--ion-color-light-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-light-shade) !important;
    --ion-color-tint: var(--ion-color-light-tint) !important;
  }
  
  .ion-color-medium {
    --ion-color-base: var(--ion-color-medium) !important;
    --ion-color-base-rgb: var(--ion-color-medium-rgb) !important;
    --ion-color-contrast: var(--ion-color-medium-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-medium-shade) !important;
    --ion-color-tint: var(--ion-color-medium-tint) !important;
  }
  
  .ion-color-dark {
    --ion-color-base: var(--ion-color-dark) !important;
    --ion-color-base-rgb: var(--ion-color-dark-rgb) !important;
    --ion-color-contrast: var(--ion-color-dark-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-dark-shade) !important;
    --ion-color-tint: var(--ion-color-dark-tint) !important;
  }

  .ion-color-grey {
    --ion-color-base: var(--ion-color-grey) !important;
    --ion-color-base-rgb: var(--ion-color-grey-rgb) !important;
    --ion-color-contrast: var(--ion-color-grey-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-grey-shade) !important;
    --ion-color-tint: var(--ion-color-grey-tint) !important;
  }
  
  .ion-color-white {
    --ion-color-base: var(--ion-color-white) !important;
    --ion-color-base-rgb: var(--ion-color-white-rgb) !important;
    --ion-color-contrast: var(--ion-color-white-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-white-shade) !important;
    --ion-color-tint: var(--ion-color-white-tint) !important;
  }

  .ion-color-black {
    --ion-color-base: var(--ion-color-black) !important;
    --ion-color-base-rgb: var(--ion-color-black-rgb) !important;
    --ion-color-contrast: var(--ion-color-black-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-black-shade) !important;
    --ion-color-tint: var(--ion-color-black-tint) !important;
  }
}

@media (prefers-color-scheme: dark-bk) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    /** primary **/
    --ion-color-primary: #FFD256;
    --ion-color-primary-rgb: 255,210,86;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #e0b94c;
    --ion-color-primary-tint: #ffd767;

    /** secondary **/
    --ion-color-secondary: #FFFCF2;
    --ion-color-secondary-rgb: 255,252,242;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #e0ded5;
    --ion-color-secondary-tint: #fffcf3;

    /** tertiary **/
    --ion-color-tertiary: #FFEEB5;
    --ion-color-tertiary-rgb: 255,238,181;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #e0d19f;
    --ion-color-tertiary-tint: #fff0bc;

    --ion-color-success: #A6C64C;
    --ion-color-success-rgb: 166,198,76;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #92ae43;
    --ion-color-success-tint: #afcc5e;

    --ion-color-warning: #FEDD6B;
    --ion-color-warning-rgb: 254,221,107;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0c25e;
    --ion-color-warning-tint: #fee07a;

    --ion-color-danger: #BC5F5F;
    --ion-color-danger-rgb: 188,95,95;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #a55454;
    --ion-color-danger-tint: #c36f6f;

    --ion-color-dark: #464646;
    --ion-color-dark-rgb: 70,70,70;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #3e3e3e;
    --ion-color-dark-tint: #595959;

    --ion-color-medium: #939393;
    --ion-color-medium-rgb: 147,147,147;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #818181;
    --ion-color-medium-tint: #9e9e9e;

    --ion-color-light: #F7F7F7 !important;
    --ion-color-light-rgb: 247,247,247 !important;
    --ion-color-light-contrast: #000000 !important;
    --ion-color-light-contrast-rgb: 0,0,0 !important;
    --ion-color-light-shade: #d8d8d8 !important;
    --ion-color-light-tint: #f6f6f6 !important;

    --ion-color-grey: #EDEDED;
    --ion-color-grey-rgb: 237,237,237;
    --ion-color-grey-contrast: #000000;
    --ion-color-grey-contrast-rgb: 0,0,0;
    --ion-color-grey-shade: #d1d1d1;
    --ion-color-grey-tint: #efefef;
    
    --ion-color-white: #151515;
    --ion-color-white-rgb: 21,21,21;
    --ion-color-white-contrast: #ffffff;
    --ion-color-white-contrast-rgb: 255,255,255;
    --ion-color-white-shade: #121212;
    --ion-color-white-tint: #2c2c2c;

    --ion-color-black: #ffffff;
    --ion-color-black-rgb: 255,255,255;
    --ion-color-black-contrast: #44380B;
    --ion-color-black-contrast-rgb: 0,0,0;
    --ion-color-black-shade: #e0e0e0;
    --ion-color-black-tint: #ffffff;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
  }
}