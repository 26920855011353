* ::-webkit-scrollbar {
  display: none;
}

.plt-desktop {
  * {
    user-select: none;
  }
}

.deleted-titlebar {
  --min-height: 20px;
}

ion-toolbar {
  --border-width: 0 !important;
}

ion-item-options {
  border-bottom-width: 0px !important;
}

ion-tab-bar {
  // border: none;
}

.skeleton {
  border-radius: 25px !important;
  --border-radius: 25px !important;
}

.skeleton-div {
  width: 100%;
}

.fab-horizontal-end {
  bottom: 15px !important;
}

.error-item {
  --min-height: 20px;
}

.error-div {
  margin-top: 2px;
  width: 100%;
  min-height: 12px;
  font-size: 12px !important;
  color: var(--ion-color-danger);
}

.error-message {
  ion-icon {
    font-size: 14px;
    vertical-align: bottom;
  }
}

.free-seating {
  padding: 1px 3px;
  background: #000000;
  color: #ffffff;
}

.popover-download-app, .modal-backdrop {
  --backdrop-opacity: 0.6 !important;
}
.popover-download-app::part(content) {
  left: 0 !important;
  top: 0 !important;
  width: 100%;
  border-radius: 0;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
}

.text-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

ion-card {
  box-shadow: none;
}

.box-shadow {
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px
}

.variable {
  background:  var(--ion-color-primary);
  --background: var(--ion-color-primary);
  border-radius: 5px;
  padding: 3px 5px;
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative;
}
.cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}
.viewport-div {
  overflow: auto;
}

.g-btn-close {
  top: calc(var(--ion-safe-area-top) + 10px) !important;
  right: calc(var(--ion-safe-area-right) + 10px) !important;
}


// .ion-content-scroll-host {
//   position: absolute;
//   top: 0;
//   left: 0;

//   height: 100%;
//   width: 100%;
//   overflow-y: auto;
// }

.ion-page {
  display: flex;
  flex-direction: column;
}

.calendar-day {
  font-size: min(1rem, 28px) !important;
}
.calendar-day.calendar-day-active {
  font-size: min(1.2rem, 30px) !important;
}