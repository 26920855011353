.full-screen-width {
  width: 100vw;
  height: 100%;
}

.full-screen-height {
  width: 100%;
  height: 100vh;
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-width-height {
  width: 100%;
  height: 100%;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-top-6 {
  margin-top: 6px;
}

.padding-5 {
  padding: 5px;
}

.padding-6 {
  padding: 6px;
}

.padding-6-horizontal {
  padding: 0 6px;
}

.padding-10 {
  padding: 10px;
}

.alert-input.sc-ion-alert-ios {
  padding: 16px 10px !important;
  margin: 10px 0px !important;
}

ion-title {
  padding: 0 40px !important;
}

// ion-modal ion-toolbar {
//   padding: 0 !important;
// }

.ion-item-no-padding {
  --padding-start: 0px;
  --inner-padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}

.thebigday-logo-bottom-space {
  height: 108px;
}

// .toolbar-search {
// }

.toolbar-select-all {
  height: 25px;
  --min-height: 25px;
  padding: 0 10px;
}

.item-guest-list {
  margin: 2px 0;
}

.label-stacked {
  margin-top: 0;
}

// .virtual-scroll-card {
//   margin: 0px;
// }

.card-margin {
  margin: 10px;
}

// app-guest-card {
//   border-top: 3px solid transparent !important;
//   border-bottom: 3px solid transparent !important;
// }

// .item-guest-list:first-child, .item-guest-list:last-child {
//   margin: 0;
// }

// app-guest-item {
//   margin: 2px 0;
// }

ion-list {
  width: 100%;
  // --min-height: 100%;
  // min-height: 100%;
}

.wrap-newline {
  white-space: pre-wrap;
}