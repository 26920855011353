.font-normal {
  font-weight: normal !important;
}

.font-bold, ion-button {
  font-weight: bold !important;
}
  
.font-semi {
  font-weight: 600 !important;
}
  
.font-med, .subtitle {
  font-weight: 500 !important;
}

.font-reg {
  text-transform: none;
  font-weight: normal !important;
}

.font-8 {
  font-size: 8px !important;
}

.font-10, .label-stacked, .label {
  font-size: 10px !important;
}
  
.font-11 {
  font-size: 11px !important;
}
  
.font-12, .note {
  font-size: 12px !important;
}
  
.font-13 {
  font-size: 13px !important;
}
  
.font-14, .divider-label, .ionic-selectable-item {
  font-size: 14px !important;
}
  
.font-15 {
  font-size: 15px !important;
}
  
.font-16 {
  font-size: 16px !important;
}
  
.font-17 {
  font-size: 17px !important;
}
  
.font-18 {
  font-size: 18px !important;
}
  
.font-19 {
  font-size: 19px !important;
}
  
.font-20 {
  font-size: 20px !important;
}
  
.font-21 {
  font-size: 21px !important;
}
  
.font-22 {
  font-size: 22px !important;
}
  
.font-23 {
  font-size: 23px !important;
}
  
.font-24 {
  font-size: 24px !important;
}
  
.font-25 {
  font-size: 25px !important;
}
  
.font-26 {
  font-size: 26px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-34 {
  font-size: 34px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-38 {
  font-size: 38px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-42 {
  font-size: 42px !important;
}

.font-44 {
  font-size: 44px !important;
}

.font-46 {
  font-size: 46px !important;
}

.font-48 {
  font-size: 48px !important;
}

.font-50 {
  font-size: 50px !important;
}
  
.font-italic {
  font-style: italic !important;
}
  
.font-underline {
  text-decoration: underline !important;
}

.line-1 {
  line-height: 1;
}

.line-100 {
  line-height: 100%;
}

.line-120 {
  line-height: 120%;
}
  
.line-140 {
  line-height: 140%;
}
.line-150 {
  line-height: 150%;
}
.line-200 {
  line-height: 200%;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}
.letter-spacing-2 {
  letter-spacing: 2px;
}
.letter-spacing-3 {
  letter-spacing: 3px;
}
.letter-spacing-4 {
  letter-spacing: 4px;
}
.letter-spacing-5 {
  letter-spacing: 5px;
}

.font-black {
  color: #000000;
}

.font-white {
  color: #ffffff;
}

.loading-content {
  font-size: 12px;
  font-weight: normal !important;
}

ion-title {
  font-size: 14px !important;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: var(--ion-color-danger);
  
  -webkit-transform:rotate(-5deg);
  -moz-transform:rotate(-5deg);
  -ms-transform:rotate(-5deg);
  -o-transform:rotate(-5deg);
  transform:rotate(-5deg);
}