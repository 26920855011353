// ion-segment {
//     --background: none;
//     background-color: transparent;
//     padding: 0;
//     margin: 0;
//     // width: 100%;
// }

// ion-segment-button {
//     margin: 5px;
//     // background: var(--ion-color-light);
//     --background: var(--ion-color-white) !important;
//     --background-checked: var(--ion-color-warning) !important;
//     --indicator-color: var(--ion-color-warning) !important;
//     --color-checked: #000000 !important;
//     --color: #000000 !important;
//     --border-color: none;
//     --border-radius: 30px;
//     transition: none !important;
//     --transition: none !important;
//     --indicator-transition: none !important;
//     text-transform: capitalize;
//     font-size: 10px;
//     font-weight: bold;
//     height: 45px;
//     ion-label {
//         white-space: normal;
//         line-height: 120%;
//     }
// }

// ion-segment-button:first-child {
//     margin-left: 0;
// }

// ion-segment-button:last-child {
//     margin-right: 0;
// }

// ion-segment-button .segment-button-indicator {
//     padding-inline: unset !important;
// }

.seg-btn-invitedBy {
    height: 65px;
    --border-radius: 15px;
}

ion-segment {
    width: 100%;
}

ion-segment-button {
    min-width: auto;
    --min-width: auto;
}

.seg-bar {
    ion-segment-button {
        margin: 0;
        --border-radius: 0;
    }

    ion-segment-button:first-child {
        margin: 0;
        --border-radius: 20px 0 0 20px;
    }

    ion-segment-button:last-child {
        margin: 0;
        --border-radius: 0 20px 20px 0;
    }
}

.seg-btn-auto {
    ion-segment-button {
        min-width: auto;
    }
}

.seg-btn-large {
    ion-segment-button {
        min-width: 100px;
        min-height: 50px;
    }
}

.seg-btn-status {
    ion-segment-button {
        min-width: auto;
        // height: 80px;
        padding: 8px 10px 0px 10px;
        // --border-radius: 4px;
    }   
}

.white-seg-bar {
    background-color: var(--ion-color-grey) !important;
    padding: 0 5px;
    margin: 10px 0;

    ion-segment-button {
        --background: var(--ion-color-grey) !important;
        --background-checked: var(--ion-color-white) !important;
        --indicator-color: var(--ion-color-white) !important;
        height: 20px !important;
    }
    
    .segment-button-checked {
        --border-radius: 10px !important;
    }
}

.dynamic-variable-seg {

}
.dynamic-variable-seg-btn {
    background:  var(--ion-color-primary);
    --background: var(--ion-color-primary);
    --background-checked:  var(--ion-color-primary) !important;
    --indicator-color:  var(--ion-color-primary) !important;
    --color-checked: var(--ion-color-dark) !important;
    --color: var(--ion-color-dark) !important;
    margin: 0 3px;
    padding: 0 5px;
    min-width: auto;
    min-height: 20px;
    height: 20px;
    --border-radius: 5px;
    --border-width: 0px;

    ion-text {
        text-transform: none;
        font-weight: normal;
        font-size: 10px;
    }
}