@media only screen and (min-width: 768px) and (min-height: 600px) {
    ion-modal {
        --width: 90vw;
        --height: 90vh;
        --max-width: 800px;

    }
}

@media only screen and (min-width: 1200px) and (min-height: 800px) {
    ion-modal {
        --width: 80vw;
        --height: 80vh;
        --max-width: 800px;
    }
}

.modal-transparent::part(content) {
    background: none;
    --background: none;
    background-color: transparent;
    --background-color: transparent;
    width: 100vw;
    height: 100vh;
    ion-content {
        background-color: rgba(var(--ion-color-black-rgb), 0.3);
        background: rgba(var(--ion-color-black-rgb), 0.3);
        --background-color: rgba(var(--ion-color-black-rgb), 0.3);
        --background: rgba(var(--ion-color-black-rgb), 0.3);
    }
}

.modal-transparent, .modal-full-screen {
    --max-width: 100vw !important;
    --max-height: 100vh !important;
}

.modal-full-screen::part(content) {
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    border-radius: 0;
}

.modal-login {
    --max-width: 500px;
    --border-radius: 20px;
    margin: 0 auto;
}


    
