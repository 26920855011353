.btn-square {
  --border-radius: 0 !important;
}

// .alert-button, .picker-button, .action-sheet-button  {
//   color: var(--ion-color-dark) !important;
// }

.action-sheet-button {
  font-size: 16px !important;
}

.action-sheet-button-inner {
  color: var(--ion-color-black);
}

// .action-sheet-destructive {
//   color: var(--ion-color-danger) !important;
// }

.action-sheet-icon {
  font-size: 18px !important;
  // margin-right: 6px !important;
}

.picker-opt {
  font-size: 16px !important;
}

ion-button {
  text-transform: capitalize;
  font-size: 10px;
  font-weight: bold;
  height: 50px;
}
