// body {
//   background: var(--ion-color-light);
//   --background: var(--ion-color-light);
// }


ion-list {
  --background: none !important;
  --background-color: transparent !important;
  background: none !important;
  background-color: transparent !important;
}

.header-light {
  background: var(--ion-color-light);
}

.header-round-bottom {
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

.background-transparent {
  background: none;
  background-color: transparent;
  --background: none;
  --background-color: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
}

.background-white {
  --background: var(--ion-color-white);
  --background-color: var(--ion-color-white);
  background: var(--ion-color-white);
  background-color: var(--ion-color-white);
}

.background-black {
  --background: var(--ion-color-black);
  --background-color: var(--ion-color-black);
  background: var(--ion-color-black);
  background-color: var(--ion-color-black);
}

.background-light {
  --background: var(--ion-color-light);
  --background-color: var(--ion-color-light);
  background: var(--ion-color-light);
  background-color: var(--ion-color-light);
}

.gradient-primary {
  background: linear-gradient(rgba(var(--ion-color-primary-rgb), 1), rgba(var(--ion-color-tertiary-rgb), 1)) !important;
  --background: linear-gradient(rgba(var(--ion-color-primary-rgb), 1), rgba(var(--ion-color-tertiary-rgb), 1)) !important;
}

.gradient-primary-right {
  background: linear-gradient(to right, rgba(var(--ion-color-primary-rgb), 1), rgba(var(--ion-color-tertiary-rgb), 1)) !important;
  --background: linear-gradient(to right, rgba(var(--ion-color-primary-rgb), 1), rgba(var(--ion-color-tertiary-rgb), 1)) !important;
}

.gradient-primary-right-top {
  background: linear-gradient(to top right, rgba(var(--ion-color-primary-rgb), 1), rgba(var(--ion-color-tertiary-rgb), 1)) !important;
  --background: linear-gradient(to top right, rgba(var(--ion-color-primary-rgb), 1), rgba(var(--ion-color-tertiary-rgb), 1)) !important;
}

.gradient-primary-right-bottom {
  background: linear-gradient(to bottom right, rgba(var(--ion-color-primary-rgb), 1), rgba(var(--ion-color-tertiary-rgb), 1)) !important;
  --background: linear-gradient(to bottom right, rgba(var(--ion-color-primary-rgb), 1), rgba(var(--ion-color-tertiary-rgb), 1)) !important;
}

ion-item {
  --background-hover-opacity: 0;
}

.content-div {
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
}