.prompt-input {
  // background-color: var(--ion-color-white) !important;
  
  // --border-width: 1px !important;
  // --border-style: solid !important;
  // --border-color: var(--ion-color-primary) !important;
  // padding: 8px 10px !important;
  // --padding: 8px 10px !important;
  // padding-left: 10px !important;
  // padding-right: 10px !important;
  // --padding-top: 8px !important;
  // --padding-bottom: 8px !important;
  margin: 0 !important;
  width: 100%;
  max-width: 100%;
  // input {
  //   padding-right: 0 !important;
  // }
  vertical-align: middle;

  .input-wrapper .native-wrapper {
    background-color: var(--ion-color-white);
    border: 1px solid var(--ion-color-medium) !important;
    border-radius: 10px !important;
    --border-radius: 10px !important;
    padding: 0 16px;
  }
}

.item-card {
  border: 1px solid var(--ion-color-medium) !important;
  border-radius: 10px !important;
  --border-radius: 10px !important;
  min-height: 50px;
  --min-height: 48px;
  width: 100%;
}

ion-item.prompt-input-item, ion-item-sliding.prompt-input-item, ion-select.prompt-input-item {
  border: 1px solid var(--ion-color-medium) !important;
  border-radius: 10px !important;
  --border-radius: 10px !important;
  min-height: 50px;
  --min-height: 48px;
  width: 100%;
}

.prompt-input input {
  // margin: 0px 10px;
}

.prompt-input {
  ion-button {
    margin: 0 8px;
  }
}

.prompt-input ion-label {
  margin: 8px 10px;
}

.prompt-input .ionic-selectable-inner {
  // margin-left: 10px;
}

ion-select.prompt-input-item {
  padding: 0px 16px;
  background: var(--ion-color-white);
}

.prompt-input-item.select-padding {
  // padding: 0px 10px;
}

ion-select.prompt-input-item::part(icon) {
  position: absolute;
  right: 0;
}


// ion-input.prompt-input{
//   background: var(--ion-color-white);
// }

ion-input.prompt-input, ion-item.prompt-input, ion-select.prompt-input, .phone-input, .website-phone-input {
  min-height: 50px;
  --min-height: 48px;
}

// ion-datetime.prompt-input {
//   padding: 12.5px 10px;
// }

// .prompt-input-item  {
//   padding: 4.5px 0;
// }

ionic-selectable {
  padding: 12.5px 0 !important;
}

ion-searchbar.prompt-input {
  // height: 50px;
}

ion-select.prompt-input::part(icon) {
  position: absolute;
  right: 0;
}

.phone-input {
  // background-color: var(--ion-color-white) !important;
  border: 1px solid var(--ion-color-medium) !important;
  border-radius: 10px;
  width: 100% !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 50px !important;
  // color: var(--ion-color-dark) !important;
  --placeholder-color: var(--ion-color-light);

}

.website-phone-input {
  border: 1px solid var(--backgroundLine) !important;
  border-radius: 20px;
  width: 100% !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 50px !important;
  --placeholder-color: var(--backgroundLine);
}

.phone-input::placeholder, ::placeholder {
  color: var(--ion-color-medium);
}

.phone-input:focus, .website-phone-input:focus {
  outline: none;
}

.input-center-with-btn {
  padding-left: 30px !important;
}

.dropdown-menu.country-dropdown {
  min-width: 275px !important;
  max-width: 560px !important;
  width: 60vw !important;
  height: 100% !important;
  min-height: 135px !important;
  overflow: auto !important;
}

// ngx-intl-tel-input .country-dropdown, ngx-intl-tel-input .iti-arrow, ngx-intl-tel-input .iti__arrow {
//   // display: none !important;
// }

.iti.iti--allow-dropdown {
  width: 100%;
}

.searchbar {
  background: white !important;
  border-radius: 30px !important;
  --color: var(--ion-color-dark);
  --icon-color: var(--ion-color-medium) !important;
  padding-left: 12px !important;
  // padding: 0 5px !important;
  margin: 0;
  // padding: 10px !important;
  // height: 50px !important;
  // height: 40px !important;
  .searchbar-search-icon {
    width: 16px !important;
    --color: var(--ion-color-medium) !important;
  }
  .searchbar-input {
    background: none !important;
    font-size: 14px;
  }
}

ion-searchbar {
  padding: 0 !important;
}

.searchbar-item {
  border-radius: 25px;
  // border: 1px solid var(--ion-color-dark) !important;
  // margin: 0 10px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

.readonly-input .native-input {
  caret-color: transparent;
}

quill-editor {
  .ql-container {
    min-height: 150px;
  }
}
.ql-toolbar, .ql-container {
  border: 0 !important;
}

.ql-toolbar {
  border-bottom: 1px solid #ccc !important;
}

.ql-editor {
  height: 100% !important;
}

.ql-container {
  height: auto !important;
}


ion-item.website-input-item, ion-item-sliding.website-input-item, ion-select.website-input-item {
  border: 1px solid var(--ion-color-medium) !important;
  border-radius: 10px !important;
  --border-radius: 10px !important;
  min-height: 50px;
  --min-height: 48px;
  width: 100%;
}
ion-select.website-input-item {
  padding: 0px 16px;
  background: var(--ion-color-white);
}
ion-select.website-input-item::part(icon) {
  position: absolute;
  right: 0;
}

.website-input {
  margin: 0 !important;
  width: 100%;
  max-width: 100%;
  vertical-align: middle;

  .input-wrapper .native-wrapper {
    background-color: var(--ion-color-white);
    border: 1px solid var(--ion-color-medium) !important;
    border-radius: 10px !important;
    --border-radius: 10px !important;
    padding: 0 16px;
  }
}

.website-input-phone {
  border: 1px solid var(--ion-color-medium) !important;
  border-radius: 10px;
  width: 100% !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  padding-left: 50px !important;
  --placeholder-color: var(--ion-color-medium);
}

.website-input-card {
  border: 1px solid var(--ion-color-medium) !important;
  border-radius: 10px;
}
