.website-title {
    font-size: 1.5em;
}

.website-status-outline-card {
    color: var(--btnFont) !important;
    --color: var(--btnFont) !important;
    border-color: var(--btnBackground) !important;
    --border-color: var(--btnBackground) !important;
    ion-icon, ion-item {
        color: var(--btnFont) !important;
    }
}

.website-status-solid-card {
    background-color: var(--btnBackground);
    color: var(--btnFont) !important;
    --color: var(--btnFont) !important;
    ion-icon, ion-item {
        color: var(--btnFont) !important;
    }
}

.website-clear-btn {
    color: var(--btnBackground) !important;
    --color: var(--btnBackground) !important;
    border-color: var(--btnBackground) !important;
    --border-color: var(--btnBackground) !important;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    ion-icon {
        color: var(--btnBackground) !important;
    }
}

.website-outline-btn {
    color: var(--btnFont) !important;
    --color: var(--btnFont) !important;
    border-color: var(--btnBackground) !important;
    --border-color: var(--btnBackground) !important;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    ion-icon {
        color: var(--btnFont) !important;
    }
}

.website-solid-btn {
    color: var(--btnFont) !important;
    --color: var(--btnFont) !important;
    --background: var(--btnBackground);
    --background-activated: var(--btnBackground);
    --background-focused: var(--btnBackground);
    --background-hover: var(--btnBackground);
    ion-icon {
        color: var(--btnFont) !important;
    }
}

.website-selected-menu-item {
    color: var(--backgroundColor) !important;
    --color: var(--backgroundColor) !important;
    background: var(--backgroundLine) !important;
    --background: var(--backgroundLine) !important;    
}

.website-popup {
    * {
        font-family: var(--font) !important;
        color: var(--backgroundLine) !important;
        --color: var(--backgroundLine) !important;
    }
    ion-card {
        background: var(--backgroundColor) !important;
        --background: var(--backgroundColor) !important;
    }
}