@font-face {
    font-family: 'SFProText';
    src: url('./SFProText/SFProText-Bold.eot');
    src: url('./SFProText/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
        url('./SFProText/SFProText-Bold.woff2') format('woff2'),
        url('./SFProText/SFProText-Bold.woff') format('woff'),
        url('./SFProText/SFProText-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText';
    src: url('./SFProText/SFProText-Semibold.eot');
    src: url('./SFProText/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
        url('./SFProText/SFProText-Semibold.woff2') format('woff2'),
        url('./SFProText/SFProText-Semibold.woff') format('woff'),
        url('./SFProText/SFProText-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText';
    src: url('./SFProText/SFProText-Medium.eot');
    src: url('./SFProText/SFProText-Medium.eot?#iefix') format('embedded-opentype'),
        url('./SFProText/SFProText-Medium.woff2') format('woff2'),
        url('./SFProText/SFProText-Medium.woff') format('woff'),
        url('./SFProText/SFProText-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText';
    src: url('./SFProText/SFProText-Regular.eot');
    src: url('./SFProText/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
        url('./SFProText/SFProText-Regular.woff2') format('woff2'),
        url('./SFProText/SFProText-Regular.woff') format('woff'),
        url('./SFProText/SFProText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Great Vibes';
    src: url('./GreatVibes/GreatVibes-Regular.eot');
    src: url('./GreatVibes/GreatVibes-Regular.eot?#iefix') format('embedded-opentype'),
        url('./GreatVibes/GreatVibes-Regular.woff2') format('woff2'),
        url('./GreatVibes/GreatVibes-Regular.woff') format('woff'),
        url('./GreatVibes/GreatVibes-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PingFang SC';
    src: url('./PingFang/PingFangSC.eot');
    src: url('./PingFang/PingFangSC.eot?#iefix') format('embedded-opentype'),
        url('./PingFang/PingFangSC.woff2') format('woff2'),
        url('./PingFang/PingFangSC.woff') format('woff'),
        url('./PingFang/PingFangSC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PingFang TC';
    src: url('./PingFang/PingFangTC.eot');
    src: url('./PingFang/PingFangTC.eot?#iefix') format('embedded-opentype'),
        url('./PingFang/PingFangTC.woff2') format('woff2'),
        url('./PingFang/PingFangTC.woff') format('woff'),
        url('./PingFang/PingFangTC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'SFProText', 'PingFang TC', 'PingFang SC';
}
:root {
    --ion-font-family: 'SFProText', 'PingFang TC', 'PingFang SC';
}

.font {
  *, :root {
    font-family: var(--font);
    --ion-font-family: var(--font);
  }
}

.arial {
    *, :root {
      font-family: "Arial", sans-serif;
      --ion-font-family: "Arial", sans-serif;
      font-weight: 400;
      font-style: normal;
    }
    
  }
  
  .roboto {
    *, :root {
      font-family: "Roboto", sans-serif;
      --ion-font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-style: normal;
    }
    
  }
  
  .jersey-15 {
    *, :root {
      font-family: "Jersey 15", sans-serif;
      --ion-font-family: "Jersey 15", sans-serif;
      font-weight: 400;
      font-style: normal;
    }
      
  }
    
  .noto-sans-tc {
    *, :root {
      font-family: "Noto Sans TC", sans-serif;
      --ion-font-family: "Noto Sans TC", sans-serif;
      font-optical-sizing: auto;
      font-weight: 100;
      font-style: normal;
    }
      
  }
  
  .open-sans {
    *, :root {
      font-family: "Open Sans", sans-serif;
      --ion-font-family: "Open Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 100;
      font-style: normal;
    }
      
  }
    
  .sans-serif {
    *, :root {
      font-family: sans-serif;
      --ion-font-family: sans-serif;
      font-weight: 400;
      font-style: normal;
    }
      
  }