.icon-dash {
  font-size: 38px;
}

.icon-cactus {
  font-size: 60px;
}

.icon-menu {
  font-size: 20px;
}

.toast-message > ion-icon {
  vertical-align: bottom !important;
}

.ionic-selectable-icon-inner {
  color: var(--ion-color-medium) !important;
}

.select-icon {
  margin-right: 16px !important;
}

ion-select::part(icon) {
  color: var(--ion-color-medium) !important;
  // margin-right: 6px !important;
}

.wa-read-icon {
  color: #4FB6EC !important;
}

.icon-cactus {
  font-size: 60px;
}

.back-icon {
  font-size: 22px;
}