.qrcodeImg > img {
  max-width: 200px !important;
  max-height: 200px !important;
}

.small-avatar, .big-avatar, .small-avatar ion-img, .big-avatar ion-img {
  background-color: var(--ion-color-white);
  border-radius: 50% !important;
  --border-radius: 50% !important;
  overflow: hidden;
}
  
.small-avatar {
  width: 53px;
  height: 53px;
}

.small-avatar ion-icon, .big-avatar ion-icon {
  width: 100%;
  height: 100%;
}
  
.big-avatar {
  width: 83px;
  height: 83px;
}

.small-avatar ion-img, .big-avatar ion-img {
  width: 100%;
  height: 100%;
}
  
.online-border {
  border: 2px solid var(--ion-color-success);
  // &.small-avatar ion-img {
  //   width: 50px;
  //   height: 50px;
  // }
  // &.big-avatar ion-img {
  //   width: 80px;
  //   height: 80px;
  // }
}
  
.offline-border {
  border: 2px solid var(--ion-color-danger);
  // &.small-avatar ion-img {
  //   width: 50px;
  //   height: 50px;
  // }
  // &.big-avatar ion-img {
  //   width: 80px;
  //   height: 80px;
  // }
}

.thebigday-logo {
  width: 120px;
  content: url('/assets/wedding/img/logo/thebigday-logo-round.webp');
}

.thebigday-logo-div {
  width: 100%;
  height: 120px;
}

.spinner-custom {
  width: 40px;
  margin: 0 auto;
}

.slide-gallery img, .slide-gallery ion-img {
  // object-fit: cover !important;
  object-position: center !important;
  // max-height: 380px !important;
  // --max-height: 380px !important;
}
